import type { EventV2, MicroCMSAPIContentResponse, ScheduleV2Types } from "./microcms.server";

export const sortEventsBySchedules = (events: EventV2[] | MicroCMSAPIContentResponse<EventV2>[]) => {
  return events.sort((a, b) => {
    const as = a.schedules.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))[0];
    const bs = b.schedules.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))[0];
    return new Date(as?.date) > new Date(bs?.date) ? 1 : -1;
  });
};

export const sortSchedulesByDate = (dates: ScheduleV2Types[]) => {
  return dates.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1));
};
